import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import $ from 'jquery'; 

function Locations() {
   const locations_lg = require('../../public/content/'+window.language+'/page_locations');
   const all_locations_json=locations_lg.LocationBox;
   const onSubmitContactForm = (event) => {
      event.preventDefault();
      try {
        $.ajax({
            tytype: 'POST',
            url: "https://script.google.com/macros/s/AKfycbzT53TYReYHqfp4EIQhKEsTwd-rxhZzc28p7T8za0rb3E2HImkCbblZek4-paAOGdo/exec",
            data: $('#nunaformhome').serialize(),
            headers: { 
               'Content-Type': 'application/x-www-form-urlencoded',
               'Access-Control-Allow-Origin': '*'
             },
            success:function(result)
            { console.log("result", result);
                if(result.result==='success'){
                    $('#name').val('');
                    $('#phone').val('');
                    $('#city').val('');
                    $('#feedback').val('');
                    alert('Your registration was successfully submitted!');
                }else{
                  alert('Datas Not submitted!');
                }
            }
          });
        } catch (e) {
            alert(`Datas Not submitted! ${e.message}`);
        }
    }

    function ChooseLocationBox(){
      var selected_location=$('#selected_location').val();
      var all_locations=all_locations_json;
      var output='';
      $.each(all_locations , function (index, value){  
         if(selected_location!==''){
            if(selected_location===value['Location']){
               output+='<div class="col-12 col-lg-4 col-sm-6"><div class="location_cont_mainouter"><div class="location_imgwrap"><img src="'+value['image']+'" alt=""/><div class="location_number">'+value['LocationNumber']+'</div></div><div class="location_maincont_wrap"><div class="location_cont_inner"><div class="location_iconwrap"><img src="assets/img/location_icon/pin.png" alt=""/></div><div class="location_contrightwrap"><h3>'+value['Location']+'</h3></div></div><div class="location_cont_inner"><div class="location_iconwrap"><img src="assets/img/location_icon/call.png" alt=""/></div><div class="location_contrightwrap"><h3>'+value['PhoneText']+'</h3><h4>'+value['Phone']+'</h4></div></div><div class="location_cont_inner"><div class="location_iconwrap"><img src="assets/img/location_icon/address.png" alt=""/></div><div class="location_contrightwrap"><h3>'+value['AddressText']+'</h3><h4>'+value['Address']+'</h4></div></div></div></div></div>';
            }
         }else{
            output+='<div class="col-12 col-lg-4 col-sm-6"><div class="location_cont_mainouter"><div class="location_imgwrap"><img src="'+value['image']+'" alt=""/><div class="location_number">'+value['LocationNumber']+'</div></div><div class="location_maincont_wrap"><div class="location_cont_inner"><div class="location_iconwrap"><img src="assets/img/location_icon/pin.png" alt=""/></div><div class="location_contrightwrap"><h3>'+value['Location']+'</h3></div></div><div class="location_cont_inner"><div class="location_iconwrap"><img src="assets/img/location_icon/call.png" alt=""/></div><div class="location_contrightwrap"><h3>'+value['PhoneText']+'</h3><h4>'+value['Phone']+'</h4></div></div><div class="location_cont_inner"><div class="location_iconwrap"><img src="assets/img/location_icon/address.png" alt=""/></div><div class="location_contrightwrap"><h3>'+value['AddressText']+'</h3><h4>'+value['Address']+'</h4></div></div></div></div></div>';
         }
       });
       $('#location_output').html(output);
    }

  return (
      <div>	
      <div class="owl-slider">
      <OwlCarousel margin={0} items={1} autoplay ={true} smartSpeed={800} autoplayTimeout={7000} loop nav id="carousel" class="owl-carousel">
            { locations_lg.SliderImages.map(slider_img => (	
               <div class="item">
                  <img src={process.env.PUBLIC_URL +'/'+ slider_img} alt="banner" />
               </div>
            ) ) }
      </OwlCarousel>
      </div>

      <section class="location_mainsec">
         <div class="container">
            <div class="row">
               <div class="col-md-6 offset-md-3">
                  <div class="sectiontitle_wrap location_titlewrap">
                     <h1>{locations_lg.Locations.Title}</h1>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-md-8 offset-md-2">
                  <div class="location_dropdown">
                     <form class="location_form">
                        <div class="location_wrapform">
                           <select class="form-control" id="selected_location" onChange={ChooseLocationBox}>
                                 <option value="">All Locations</option>
                              { locations_lg.Locations.Option.map(locations => (
                                 <option value={locations}>{locations}</option>
                              ) ) }
                           </select>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
            
            
            <div class="row" id="location_output">
	       { locations_lg.LocationBox.map(locations_box => (
               <div class="col-12 col-lg-4 col-sm-6">
                  <div class="location_cont_mainouter">
                     <div class="location_imgwrap">
                        <img src={process.env.PUBLIC_URL +'/'+ locations_box.image} alt="" />
                        <div class="location_number">{locations_box.LocationNumber}</div>
                     </div>
                     <div class="location_maincont_wrap">
                        <div class="location_cont_inner">
                           <div class="location_iconwrap">
                              <img src="assets/img/location_icon/pin.png" alt="" />
                           </div>
                           <div class="location_contrightwrap">
                              <h3>{locations_box.Location}</h3>
                           </div>
                        </div>
                        <div class="location_cont_inner">
                           <div class="location_iconwrap">
                              <img src="assets/img/location_icon/call.png" alt="" />
                           </div>
                           <div class="location_contrightwrap">
                              <h3>{locations_box.PhoneText}</h3>
                              <h4>{locations_box.Phone}</h4>
                           </div>
                        </div>
                        <div class="location_cont_inner">
                           <div class="location_iconwrap">
                              <img src="assets/img/location_icon/address.png" alt="" />
                           </div>
                           <div class="location_contrightwrap">
                              <h3>{locations_box.AddressText}</h3>
                              <h4>{locations_box.Address}
                              </h4>
                           </div>
                        </div>
                     </div>
                  </div>
               </div> 
	       ) ) }
            </div>
         </div>
      </section>

      <section class="locationpage_contacts_mainsec">
         <div class="container">
            <div class="row">
               <div class="col-md-10 offset-md-1">
                  <div class="sectiontitle_wrap contactus_texttransform">
                     <h1>{locations_lg.ContactUs.Title}</h1>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-md-8 offset-md-2">
                  <div class="form_wrap">
                     <form id="contactus_form" onSubmit={onSubmitContactForm}>
                        <div class="form-group">
                           <input type="hidden" name="page" value="locations"/>
                           <label>{locations_lg.ContactUs.NameField} <span>*</span></label>
                           <input class="form-control" id="name" type="text" name="name" required autoComplete="off"/>
                        </div>
                        <div class="form-group">
                           <label>{locations_lg.ContactUs.PhoneField} <span>*</span></label>
                           <input class="form-control" id="phone" type="number" name="phone" required autoComplete="off"/>
                        </div>
                        <div class="form-group">
                           <label>{locations_lg.ContactUs.SelectCity} <span>*</span></label>
                           <select class="form-control" id="city" name="city" required>
                           { locations_lg.Locations.Option.map(locations => (
                                 <option value={locations}>{locations}</option>
                              ) ) }
                           </select>
                        </div>
                        <div class="form-group">
                           <label>{locations_lg.ContactUs.Feedback}</label>
                           <textarea class="form-control" id="feedback" name="feedback"></textarea>
                        </div>
                        <div class="form-group contctus_buttonwrap">
                           <button type="submit">{locations_lg.ContactUs.Submit}</button>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </section>
      </div>
  );
}

export default Locations;