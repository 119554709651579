import React from "react";
import $ from 'jquery'; 

function AdultCare() {
  
 const adultcare_lg = require('../../public/content/'+window.language+'/page_adult_care');

 function ChangeSize(){
    var sizes=$('#sizes').val();
    $('html, body').animate({
        scrollTop: $('#'+sizes).offset().top
    }, 200);
    return false;
 }
  return (
    <div>
        <section class="bilan_ultra_mainsec">
            <div class="bilan_ultra_mainimg_wrap">
                <img src={process.env.PUBLIC_URL + '/'+adultcare_lg.TopBannerImg}  alt=""/>
            </div>
            <section class="bilan_ultra_formsec">
                <div class="container">
                    <div class="row">
                        <div class="col-md-8 offset-md-2">
                            <div class="location_dropdown">
                                <form class="location_form">
                                    <div class="location_wrapform">
                                        <select class="form-control" name="sizes" id="sizes" onChange={ChangeSize}>
                                            <option value="">Choose  Size</option>
                                            <option value="M">M</option>
                                            <option value="L">L</option>
                                            <option value="XL">XL</option>
                                        </select>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>

        <section class="nuna_control_mainsec">
             <div  class="container">
                    <div class="row">
                        <div class="col-md-8 offset-md-2">
                            <div class="adultcaretitle_wrap">
                                <h1>{adultcare_lg.NanoOdorcontrolsystem.Title}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            <div class="container">
                <div class="row">
                { adultcare_lg.NanoOdorcontrolsystem.NanoOdorcontrolsystemvalues.map(systemvalue => (	
                    <div class="col-md-6">
                       <div class="adultcare_control_contbox  clearfix">
                            <div class="adultcare_control_iconbox">
                                <div class="adultcare_control_innerbox">
                                    <img src={process.env.PUBLIC_URL + '/'+systemvalue.Image} alt=""/>
                                </div>
                            </div>
                            <div class="adultcare_control_contentwrap">
                                <h3><span>{systemvalue.Title}</span></h3>
                                <p>{systemvalue.Description}</p>
                            </div>
                        </div>
                    </div>
                ) ) }
                </div>
            </div>
        </section>
        <section class="howtouse_mainsec">
             <div  class="container">
                    <div class="row">
                        <div class="col-md-6 offset-md-3">
                            <div class="adultcare_maintitle_wrap">
                                <h1>{adultcare_lg.HowtoUse}</h1>
                            </div>
                            <div class="position_titlewrap">
                                <h3><span>{adultcare_lg.STANDINGPOSITION.Title}</span></h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="row">
                  { adultcare_lg.STANDINGPOSITION.StandingPositionValues.map(standing => (	
                    <div class="col-md-6">
                    <div class="adultcare_control_contbox  clearfix">
                            <div class="adultcare_controluse_iconbox">
                                <div class="adultcare_control_innerbox">
                                    <img src={process.env.PUBLIC_URL + '/'+standing.Image} alt=""/>
                                </div>
                            </div>
                            <div class="adultcare_controluse_contentwrap">
                                <p>{standing.Description}</p>
                            </div>
                        </div>
                    </div>
                 ) ) }
                    </div>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 offset-md-3">          
                            <div class="position_titlewrap">
                                <h3><span>{adultcare_lg.LYINGDOWNPOSITION.Title}</span></h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="row">
                    { adultcare_lg.LYINGDOWNPOSITION.LyingPositionValues.map(lying => (	
                     <div class="col-md-6">
                       <div class="adultcare_control_contbox  clearfix">
                            <div class="adultcare_controluse_iconbox">
                                <div class="adultcare_control_innerbox">
                                    <img src={process.env.PUBLIC_URL + '/'+lying.Image} alt=""/>
                                </div>
                            </div>
                            <div class="adultcare_controluse_contentwrap">
                                <p>{lying.Description}</p>
                            </div>
                        </div>
                    </div> 
                    ) ) }
                    </div>
                </div>
        </section>

        <section class="adultcare_diaper_mainsec">
            <div class="container">
                <div class="row" id="XL">
                    <div class="col-6 col-lg-6 col-sm-6">
                        <div class="diaper_imgwrap">
                            <img src={process.env.PUBLIC_URL + '/'+adultcare_lg.Section1.Image1} alt=""/>
                        </div>
                    </div>
                    <div class="col-6 col-lg-6 col-sm-6">
                        <div class="diaper_imgwrap">
                            <img src={process.env.PUBLIC_URL + '/'+adultcare_lg.Section1.Image2} alt=""/>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 offset-md-3">
                        <div class="adultcare_diaper_size_mainwrap">
                            <div class="diaper_sizeleft">
                                <h3>{adultcare_lg.Section1.Title1}</h3>
                            </div>
                            <div class="diaper_sizeright">
                                <img src={process.env.PUBLIC_URL + '/'+adultcare_lg.Section1.TypeImage} alt=""/>
                                <h3>{adultcare_lg.Section1.Title2}</h3>
                                <h4>{adultcare_lg.Section1.Title3}</h4>
                            </div>
                            <div class="size_bottom_text">
                                <h2>{adultcare_lg.Section1.Title4}</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="adultcare_diaper_borderwrap"></div>
                    </div>
                </div>
              <div class="row" id="L">
                    <div class="col-6 col-lg-6 col-sm-6">
                        <div class="diaper_imgwrap">
                            <img src={process.env.PUBLIC_URL + '/'+adultcare_lg.Section2.Image1} alt=""/>
                        </div>
                    </div>
                    <div class="col-6 col-lg-6 col-sm-6">
                        <div class="diaper_imgwrap">
                            <img src={process.env.PUBLIC_URL+'/'+adultcare_lg.Section2.Image2} alt="" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 offset-md-3">
                        <div class="adultcare_diaper_size_mainwrap">
                            <div class="diaper_sizeleft">
                                <h3>{adultcare_lg.Section2.Title1}</h3>
                            </div>
                            <div class="diaper_sizeright">
                                <img src={process.env.PUBLIC_URL + '/'+adultcare_lg.Section2.TypeImage} alt=""/>
                                <h3>{adultcare_lg.Section2.Title2}</h3>
                                <h4>{adultcare_lg.Section2.Title3}</h4>
                            </div>
                            <div class="size_bottom_text">
                                <h2>{adultcare_lg.Section2.Title4}</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="adultcare_diaper_borderwrap"></div>
                    </div>
                </div>
                <div class="row" id="M">
                    <div class="col-6 col-lg-6 col-sm-6">
                        <div class="diaper_imgwrap">
                           <img src={process.env.PUBLIC_URL + '/'+adultcare_lg.Section3.Image1} alt=""/>
                        </div>
                    </div>
                    <div class="col-6 col-lg-6 col-sm-6">
                        <div class="diaper_imgwrap">
                            <img src={process.env.PUBLIC_URL + '/'+adultcare_lg.Section3.Image2} alt=""/>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 offset-md-3">
                        <div class="adultcare_diaper_size_mainwrap">
                            <div class="diaper_sizeleft">
                                <h3>{adultcare_lg.Section3.Title1}</h3>
                            </div>
                            <div class="diaper_sizeright">
                                <img src={process.env.PUBLIC_URL + '/'+adultcare_lg.Section3.TypeImage} alt=""/>
                                <h3>{adultcare_lg.Section3.Title2}</h3>
                                <h4>{adultcare_lg.Section3.Title3}</h4>
                            </div>
                            <div class="size_bottom_text">
                                <h2>{adultcare_lg.Section3.Title4}</h2>
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
        </section>
    </div>
  );
}

export default AdultCare;