import React from "react";

function Contactus() {
  return (
    <div>
      <br/><br/>
      <center><h1>About Our Company</h1></center>
      <br/>
      <center>
        <div  class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="sectiontitle_wrap">
                            <p>At Dauus, we are dedicated to developing products that improve our customers' lives and offer them the best value for their money.<br/>
                               Our products are manufactured to the highest standards and are made using safe nourishing ingredients.<br/>
                               Our firm's vision is to become a leading international brand. Subsequently, we continue to innovate and expand our reach into new markets to ensure that our products are easily accessible to all.<br/>
                               We welcome and value customer feedback from all our customers whether retail or wholesale. Understanding your needs helps us serve you better and grow our brand in the right direction.<br/>
                               Of course, none of this would be possible without our highly talented Dauus team of staff. Their commitment to our brand values and customer service is remarkable and remains the pillar of our success.
                            </p>
                            </div>
                        </div>
                    </div>
                </div>
      </center>
    </div>
  );
}

export default Contactus;