import React from "react";

function NunaCereal() {
   const nunacereal_lg = require('../../public/content/'+window.language+'/page_baby_foods');

  return (
      <div>
      <section class="nunacareal_pagemainsec">
         <div class="container">
            <div class="row">
               <div class="col-lg-8 col-12 offset-lg-2">
                  <div class="babycare_maintitle_wrap">
                     <h3>{nunacereal_lg.Title}</h3>
                  </div>
               </div>
            </div>
         </div>
         <div class="nuna_main_imagewrap">
            <img src={process.env.PUBLIC_URL +'/'+ nunacereal_lg.MainImage} alt="" />
         </div>
         <div class="nunacareal_baby_imagewrap">
            <img src={process.env.PUBLIC_URL +'/'+ nunacereal_lg.BabyImage} alt="" />
         </div>
      </section>
      <section class="nutrition_chat_mainsec">
         <div class="container">
            <div class="row">
               <div class="col-lg-10 col-12 offset-lg-1">
                  <div class="nutrition_chat_imgwrap">
                     <img src={process.env.PUBLIC_URL +'/'+ nunacereal_lg.NutritionImage} alt="" />
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="preparation_stepsmainsec">
         <div class="container">
            <div class="row">
               <div class="col-lg-8 col-12 offset-lg-2">
                  <div class="babycare_maintitle_wrap nuna_ceral_preparationstep_titlewrap">
                     <h3>{nunacereal_lg.Preparation.Title}</h3>
                  </div>
               </div>
	       
	       { nunacereal_lg.Preparation.Steps.map(prep_step => (	
               <div class="col-lg-12  col-12">
                  <div class="preparation_steps_outerwrap clearfix">
                     <div class="row">
                        <div class="col-lg-5 col-5">
                           <div class="preparation_step_iconwrap">
                              <div class="row">
                                 <div class="col-lg-4 col-4">
                                    <div class="nuna_ceralpreparation_step_countwrap">{prep_step.Step}</div>
                                 </div>
                                 <div class="col-lg-8 col-8">
                                    <div class="preparation_stepimgwrap">
                                       <img src={process.env.PUBLIC_URL +'/'+ prep_step.Image} alt="" />
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-7 col-7">
                           <div class="nuna_ceral_preparation_step_contentwrap">
                              <h3>{prep_step.Description}</h3>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
	       ) ) }

               <div class="col-md-12 col-12">
                  <div class="nuna_cereal_newshorttablewrap">
                     <div class="table-responsive">
                        <div class="nuna_short_innertablewrap">
                           <table class="table table-borderless">
                              <thead>
                                 <tr>
                                    <th>{nunacereal_lg.NutricerealSpoons.Header1}</th>
                                    <th>{nunacereal_lg.NutricerealSpoons.Header2}</th>
                                    <th>{nunacereal_lg.NutricerealSpoons.Header3}</th>
                                 </tr>
                              </thead>
                              <tbody>
			         
				                     { nunacereal_lg.NutricerealSpoons.BoxValues.map(boxval => (
				                     <tr class="nuna_short_table_borderwrap">
                                    <td>{boxval.Age}</td>
                                    <td>{boxval.Water} </td>
                                    <td>
                                       <div class="nuna_short_table_spoonwrap">
                                          <span> {boxval.Level} </span><span class="spoon_imgwrap"><img src={boxval.Image} alt="" /></span><span> {boxval.Grams}</span>
                                       </div>
                                    </td>
                                 </tr>   				     
				                     ) ) }

                                 {/*
				                     <tr class="nuna_short_table_borderwrap">
                                    <td>6-8</td>
                                    <td>150ml </td>
                                    <td>
                                       <div class="nuna_short_table_spoonwrap">
                                          <span> 7x </span><span class="spoon_imgwrap"><img src="assets/img/nuna_cereal/tablespoon.png" alt="" /></span><span> 45g</span>
                                       </div>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td>8-12</td>
                                    <td>200ml </td>
                                    <td>
                                       <div class="nuna_short_table_spoonwrap">
                                          <span> 10x </span><span class="spoon_imgwrap"><img src="assets/img/nuna_cereal/tablespoon.png" alt="" /></span><span> 67g</span>
                                       </div>
                                    </td>
                                 </tr>
                                 <tr class="nuna_short_table_border_radiuswrap">
                                    <td>12-36</td>
                                    <td>250ml </td>
                                    <td>
                                       <div class="nuna_short_table_spoonwrap">
                                          <span> 12x </span><span class="spoon_imgwrap"><img src="assets/img/nuna_cereal/tablespoon.png" alt="" /></span><span> 80g</span>
                                       </div>
                                    </td>
                                 </tr>
				                     */}
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="tom_img_mainsec">
         <div class="tom_imgwrap">
            <img src={process.env.PUBLIC_URL +'/'+ nunacereal_lg.TomImage} alt="" />
         </div>
      </section>
      <section class="nutritional_facts_mainsec">
         <div class="container">
            <div class="row">
               <div class="col-md-12">
                  <div class="table-responsive">
                     <div class="nutritional_facts_tablewrap">
                        <table class="table table-borderless">
                           <thead>
                              <tr>
                                 <th colspan="4">{nunacereal_lg.NutritionalFacts.Title}</th>
                              </tr>
                           </thead>
                           <tbody>
			      
			                     { nunacereal_lg.NutritionalFacts.Data.map(nutfacts => (
                              <tr>
                                 <td>{nutfacts.Content1}</td>
                                 <td dangerouslySetInnerHTML={{__html:nutfacts.Content2}}></td>
                                 <td dangerouslySetInnerHTML={{__html:nutfacts.Content3}}></td>
                                 <td dangerouslySetInnerHTML={{__html:nutfacts.Content4}}></td>
                              </tr>
                              ) ) }

                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>


      {/* <section class="nunalac_page_tablewrap">
         <div class="container">
            <div class="row">
               <div class="col-lg-12 col-12 col-sm-12">
                  <div class="table-responsive">
                     <div class="nuna_tablewrap">
                        <table class="table table-borderless">
                           <thead>
                              <tr>
                                 <th>{nunacereal_lg.Feedings.Column1Title}</th>
                                 <th>{nunacereal_lg.Feedings.Column2Title}</th>
                                 <th>{nunacereal_lg.Feedings.Column3Title}</th>
                                 <th>{nunacereal_lg.Feedings.Column4Title}</th>
                                 <th>{nunacereal_lg.Feedings.Column5Title}</th>
                              </tr>
                           </thead>
                           <tbody>
			                     { nunacereal_lg.Feedings.Content.map(feed => (
                              <tr>
                                 <td>{feed.Age}</td>
                                 <td>{feed.Feedings}</td>
                                 <td>{feed.Water}</td>
                                 <td>{feed.Scoops}</td>
                                 <td>{feed.FeedMl}</td>
                              </tr>
			                     ) ) }
                              <tr>
                                 <td colspan="5" class="nunalac_table_td_padd_zero">
                                    <div class="nunalac_table_bottomtextwrap">
                                       <h3>{nunacereal_lg.Feedings.Footer}</h3>
                                    </div>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="babyage_mainsec">
         <div class="container">
            <div class="row">
               <div class="col-md-6 offset-md-3">
                  <div class="preparation_steps_title_wrap">
                     <h1>{nunacereal_lg.BabyAge.Title}</h1>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-lg-6 col-12">
                  <div class="babyage_contouter_wrapnew">
                     <div class="babyage_contouter">
                        <div class="row">
                           { nunacereal_lg.BabyAge.Age1.AgeBox.map(age_box => (  
                              <div class="col-lg-4 col-4">
                                 <div class="baby_bottle_wrrap">
                                    <div class="baby_age_contwrap">
                                       {age_box.Category}
                                    </div>
                                    <img src={process.env.PUBLIC_URL +'/'+ age_box.Image} alt="" />
                                 </div>
                              </div>
                           ) ) }
                        </div>
                     </div>

                     <div class="babybottleouterwrap">
                        <div class="row">
                        { nunacereal_lg.BabyAge.Age1.Spoons.map(spoon => (  
                           <div class="col-lg-4 col-4">
                              <div class="baby_cup_wrrap">
                                 <div class="nunalac_countwrap">
                                    <span>{spoon.Spoon}</span>
                                 </div>
                                 <img src={process.env.PUBLIC_URL +'/'+ spoon.SpoonImage} alt="" />
                              </div>
                           </div>
                        ) ) }
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-lg-6 col-12">
                  <div class="babyage_contouter_wrapnew">
                     <div class="babyage_contouter">
                        <div class="row">
                        { nunacereal_lg.BabyAge.Age2.AgeBox.map(age_box => (  
                           <div class="col-lg-4 col-4">
                              <div class="baby_bottle_wrrap">
                                 <div class="baby_age_contwrap">
                                    {age_box.Category}
                                 </div>
                                 <img src={process.env.PUBLIC_URL +'/'+ age_box.Image} alt="" />
                              </div>
                           </div>
                        ) ) } 
                        </div>
                     </div>
                     <div class="babybottleouterwrap">
                        <div class="row">
                        { nunacereal_lg.BabyAge.Age2.Spoons.map(spoon => (  
                           <div class="col-lg-4 col-4">
                              <div class="baby_cup_wrrap">
                                 <div class="nunalac_countwrap">
                                 <span>{spoon.Spoon}</span>
                                 </div>
                                 <img src={process.env.PUBLIC_URL +'/'+ spoon.SpoonImage} alt="" />
                              </div>
                           </div>
                        ) ) }
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section> */}


      </div>
  );
}

export default NunaCereal;