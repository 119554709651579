import React from "react";
import { withRouter } from "react-router-dom";
import { useHistory } from 'react-router-dom'; 

function Navigation(props) {
  const history = useHistory();
  return (
        <nav class="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
            <div class="container">
                <a class="navbar-brand" onClick={() => history.push("/")}><img src={process.env.PUBLIC_URL + '/assets/img/logo.png'} alt="logo" /></a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">                    
                <i class="fas fa-bars ms-1"></i>
                </button>
                <div class="collapse navbar-collapse custom_navbar" id="navbarResponsive">
                    <ul class="navbar-nav text-uppercase ms-auto py-4 py-lg-0">
                      <li class="nav-item">
                        <a class="nav-link"  onClick={() => history.push("/baby-care")}>People Care </a>
                         <i class="fl flaticon-plus">+</i> 
                        <ul class="submenu">
                          <li><a class="nav-sublink" onClick={() => history.push("/baby-care")}>Baby Care</a></li>
                          <li><a class="nav-sublink" onClick={() => history.push("/adult-care")} >Adult Care</a></li>
                          <li><a class="nav-sublink" onClick={() => history.push("/feminine-care")} >Feminine Care</a></li>
                          <li><a class="nav-sublink" onClick={() => history.push("/beauty-care")} >Beauty Care</a></li>
                        </ul>
                      </li>
                       <li class="nav-item">
                        <a class="nav-link" onClick={() => history.push("/infant-formula")} >Nutritional </a>
                         <i class="fl flaticon-plus">+</i> 
                        <ul class="submenu">
                          <li><a class="nav-sublink" onClick={() => history.push("/infant-formula")} >Infant formula</a></li>
                          <li><a class="nav-sublink" onClick={() => history.push("/baby-foods")} >Baby foods</a></li>
                          <li><a class="nav-sublink" onClick={() => history.push("/selectmil")} >Selectmil</a></li>
                        </ul>
                      </li>
                      {/* <li class="nav-item">
                        <li><Link class="nav-link" to="/medicine">Personal Health</Link></li>
                      </li> */}
                      <li class="nav-item">
                        <a class="nav-link" onClick={() => history.push("/medicine")}>Personal Health </a>
                         <i class="fl flaticon-plus">+</i> 
                        <ul class="submenu">
                          <li><a class = "nav-sublink" onClick={() => history.push("/medicine")}>Medicine</a></li>
                          <li><a class = "nav-sublink" onClick={() => history.push("/express-clinic")}> Express clinic</a></li>
                        </ul>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" onClick={() => history.push("/about")}>Company </a>
                         <i class="fl flaticon-plus">+</i> 
                        <ul class="submenu">
                          <li><a class = "nav-sublink" onClick={() => history.push("/about")}>About</a></li>
                          <li><a class = "nav-sublink" onClick={() => history.push("/career")}>Careers</a></li>
                          <li><a class = "nav-sublink" onClick={() => history.push("/locations")}>Locations & Contact</a></li>
                        </ul>
                      </li>
                    </ul>
                </div>
            </div>
        </nav>

  );
}

export default withRouter(Navigation);