import React from "react";

function Footer() {
  return (
      <footer class="footer py-4">
          <div class="container">
              <div class="row align-items-center">
                  <div class="col-lg-6 col-md-6 my-3 my-lg-0 footer_socialwrap">
                      <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-facebook-f"></i></a>
                      <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-twitter"></i></a>
                      <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-whatsapp"></i></a>
                      <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-instagram"></i></a>
                  </div>
                  <div class="col-lg-6 col-md-6 text-md-end text-lg-end footer_socialwrap">Copyright &copy;  {(new Date().getFullYear())} Dauus.</div>
              </div>
          </div>
      </footer>
  );
}

export default Footer;