import React from "react";
import $ from 'jquery'; 

function HealthCare() {
  const health_lg = require('../../public/content/'+window.language+'/page_medicine');

  function vitaminsClick(){
    if($('.healthcarepage_mainsec').hasClass('bg_active')){
        $('.healthcarepage_mainsec').removeClass('bg_active');
      }else{
          $('.healthcarepage_mainsec').addClass('bg_active');
      }
  }
  function medicineClick(){
    $('.healthcarepage_mainsec').removeClass('bg_active');
  }

  return (
    <div className="maincontent">
      {/* <link href={process.env.PUBLIC_URL + '/assets/css/bootstrap.min.css'} rel="stylesheet" /> */}
      <section class="healthcarepage_mainsec">
        <div class="healthcare_navpills">
          <ul class="nav nav-tabs">
            <li class="nav-item">
                <a class="nav-link medicine_btn active" onClick={medicineClick} data-toggle="tab" href="#Medicine">{health_lg.TopAnchor1}</a>
            </li>
            <li class="nav-item">
                <a class="nav-link vitamins_btn" onClick={vitaminsClick} data-toggle="tab" href="#Vitamins">{health_lg.TopAnchor2}</a>
            </li>
          </ul>
        </div>

        <div class="healthcare_tabcont">
          <div class="tab-content">

            <div class="tab-pane  active" id="Medicine">
                  <div class="row">
                      <div class="col-md-8 offset-md-2">
                          <div class="sectiontitle_wrap healthcare_titlewrap_uppercase healthcare_maintitlewrap">
                              <h1>{health_lg.TopAnchor1TopHeading} <img src={process.env.PUBLIC_URL + '/assets/img/tick.jpeg'} alt="" width="35"/></h1> 
                          </div>
                      </div>
                  </div>
                  <div class="healthcare_mainimgwrap">
                      <img src={process.env.PUBLIC_URL + '/assets/img/healthcare/1.jpg'} alt="Healthcare" />
                  </div>
                  { health_lg.TopAnchor1SubTitle.map((topanchor1) => {
                  return (
                  <section class={`healthcare_subimgwrap  ${
                    topanchor1.BgWhite === "1" ? "healthcare_bg_white" : ""
                     }`}>
                      <div class="container">
                          <div class="row">
                              <div class="col-md-10">
                                  <div class="Healthcare_subtitle_wrap">
                                    <h1>{topanchor1.Title}</h1>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="healthcare_subimginner_wrap">
                          <img src={process.env.PUBLIC_URL +'/'+ topanchor1.Image}  alt="Healthcare" />
                          <div class="healthcare_desc">
                              <h3>{topanchor1.Content}</h3>
                          </div>
                      </div>
                  </section>
                  );    })    }      
            </div>

            <div class="tab-pane  fade" id="Vitamins">
            { health_lg.TopAnchor2SubTitle.map((topanchor2) => {
            return (
              <section class={`healthcare_subimgwrap  ${
                topanchor2.BgWhite === "1" ? "healthcare_bg_white" : ""
                }`}>
                <div class="container">
                  <div class="row">
                    <div class="col-md-10">
                        <div class="Healthcare_subtitle_wrap">
                          <h1>{topanchor2.Title}</h1>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="healthcare_subimginner_wrap">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-8 offset-md-2">
                        <div class="healthcare_topdesc">
                          <h3 dangerouslySetInnerHTML={{__html:topanchor2.Content}}></h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img src={process.env.PUBLIC_URL +'/'+ topanchor2.Image} alt="Healthcare" />
                </div>
              </section>
            );    })    } 
            </div>

          </div>
        </div>
      </section>
    </div>
  );
}

export default HealthCare;