import React from "react";
import $ from 'jquery'; 

function BilanUltra() {
    const bilanultra_lg = require('../../public/content/'+window.language+'/page_feminine_care');
    
    function ChangeSize(){
        var sizes=$('#sizes').val();
         $('html, body').animate({
             scrollTop: $('#'+sizes).offset().top
         }, 200);
         return false;
    }
    
  return (
    <div>
        <section class="bilan_ultra_mainsec">
            <div class="bilan_ultra_mainimg_wrap">
               <img src={process.env.PUBLIC_URL +'/'+ bilanultra_lg.HeadingImage} alt="banner" />
            </div>
            <section class="bilan_ultra_formsec">
                <div class="container">
                    <div class="row">
                        <div class="col-md-8 offset-md-2">
                            <div class="location_dropdown">
                                <form class="location_form">
                                    <div class="location_wrapform">
                                        <select class="form-control" name="sizes" id="sizes" onChange={ChangeSize}>
                                            <option value="sizes">Choose Your Size</option>
                                            { bilanultra_lg.Sizes.map(sizes => (
                                             <option value={sizes.replace(' ','_')}>{sizes}</option>
                                            ) ) }
                                        </select>
                                    </div>
                                </form>
                            </div>
                        </div> 
                    </div>
                </div>
            </section>
        </section>

        <section class="padimg_mainsec">
            <div class="padimg_wrap">
                <img src={process.env.PUBLIC_URL +'/'+ bilanultra_lg.Image2} alt=""/>
            </div>
        </section>
         
         { bilanultra_lg.SizeBoxs.map((boxs,i) => (

        <section class="bilan_ultra_padmainsec">
            <div class="container" id={boxs.Title.replace(' ','_')}>
                <div class="row">
                    <div class="col-md-12">
                        <div class="bilan_borderwrap"></div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="bilan_maintitle_wrap">
                            <h3>{boxs.Title}</h3>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8 col-6">
                        <div class="bilan_ultra_imagewrap">
                            <img src={process.env.PUBLIC_URL +'/'+ boxs.Image} alt=""/>
                        </div>
                    </div>
                    <div class="col-md-4 col-6">
                        <div class="bilan_ultra_icon_contwrap clearfix">
                            <div class="bilan_ultra_pad">
                                <div class="bilan_ultra_img_pad">
                                    <img src={process.env.PUBLIC_URL +'/'+ boxs.Pad} alt=""/>
                                </div>
                            </div>
                            <div class="bilan_ultra_content_boxwrap">
                                <div class="size_num">
                                    <h3>{boxs.Size_Num}</h3>
                                </div>
                                <p><span>{boxs.Content1}</span></p>
                                <div class="drop_wrap">
                                    {(() => {
                                    if (i===0) {
                                        return (<i class="fas fa-tint"></i>)
                                    } else if (i===1) {
                                        return (<span><i class="fas fa-tint"></i> <i class="fas fa-tint"></i></span>)
                                    } else if (i===2) {
                                        return (<span><i class="fas fa-tint"></i> <i class="fas fa-tint"></i> <i class="fas fa-tint"></i></span>)
                                    } else if (i===3) {
                                        return (<span><i class="fas fa-tint"></i> <i class="fas fa-tint"></i> <i class="fas fa-tint"></i> <i class="fas fa-tint"></i></span>)
                                    } else if (i===4) {
                                        return (<span><i class="fas fa-tint"></i> <i class="fas fa-tint"></i> <i class="fas fa-tint"></i> <i class="fas fa-tint"></i> <i class="fas fa-tint"></i></span>)
                                    } else {
                                        return (<i class="fas fa-tint"></i>)
                                    }
                                    })()}
                                </div>
                                <div class="text_size">
                                    {boxs.Content2}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="flow_titlewrap">
                            <h3>{boxs.TableTitle}</h3>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        
                        <div class="flow_table_wrap">
                            <div class="flow_table_sidetext_wrap">
                                <h4>{boxs.TableSideHeader}</h4>
                            </div>
                            <div class="table-responsive-sm">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th dangerouslySetInnerHTML={{__html:boxs.tableth2}}></th>
                                        <th dangerouslySetInnerHTML={{__html:boxs.tableth3}}></th>
                                        <th dangerouslySetInnerHTML={{__html:boxs.tableth4}}></th>
                                        <th dangerouslySetInnerHTML={{__html:boxs.tableth5}}></th>
                                        <th dangerouslySetInnerHTML={{__html:boxs.tableth6}}></th>                                      
                                    </tr>
                                </thead>
                                <tbody>
                                { boxs.tablecontent.map(boxtable => (
                                    <tr>
                                        <td>{boxtable.Column1}</td>
                                        <td class={boxtable.Column2.class}>{boxtable.Column2.content}</td>
                                        <td class={boxtable.Column3.class}>{boxtable.Column3.content}</td>
                                        <td class={boxtable.Column4.class}>{boxtable.Column4.content}</td>
                                        <td class={boxtable.Column5.class}>{boxtable.Column5.content}</td>
                                        <td class={boxtable.Column6.class}>{boxtable.Column6.content}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        ) ) }       
    </div>
  );
}

export default BilanUltra;