import React from "react";
import $ from 'jquery'; 

function Nunalac() {
   const nunalac_lg = require('../../public/content/'+window.language+'/page_infant_formula');
   function Preparation(){
      $('html, body').animate({
         scrollTop: $('#preparation').offset().top
     }, 200);
     return false;
   }
   function BabyAge(){
      $('html, body').animate({
         scrollTop: $('#babyage').offset().top
     }, 200);
     return false;
   }

  return (
      <div>
      <section class="nunalac_pagemainsec">
         <div class="container">
            <div class="row">
               <div class="col-lg-7 col-8 offset-lg-1">
                  <div class="nunalac_page_toptext">
                     <h3>{nunalac_lg.HeaderContent}</h3>
                  </div>
               </div>
               <div class="col-lg-3 col-4">
                  <div class="nunalac_logowrap">
                     <img src={process.env.PUBLIC_URL +'/'+ nunalac_lg.LogoImage} alt="" />
                  </div>
               </div>
            </div>
         </div>
         <div class="nuna_main_imagewrap">
            <img src={process.env.PUBLIC_URL +'/'+ nunalac_lg.BannerImage} alt="" />
         </div>
         <div class="container">
            <div class="row">
               <div class="col-lg-4 col-12 offset-lg-4">
                  <div class="premium_plus_imagewrap">
                     <img src={process.env.PUBLIC_URL +'/'+ nunalac_lg.PremiumPlusLogo} alt="" />
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-md-4 offset-md-2">
                  <div class="nunalac_newbuttonwrap">
                     <a href={() => false} onClick={Preparation} className="cursor_point">{nunalac_lg.AnchorTitle1}</a>
                  </div>
               </div>
               <div class="col-md-4">
                  <div class="nunalac_newbuttonwrap active">
                     <a href={() => false} onClick={BabyAge} className="cursor_point">{nunalac_lg.AnchorTitle2}</a>
                  </div>
               </div>
            </div>
         </div>

      </section>
      <section class="vitamins_mainsec">
         <div class="container">
            <div class="row">
               <div class="col-md-6 offset-md-3">
                  <div class="vitamins_title_wrap">
                     <h1>{nunalac_lg.Ingrediants}</h1>
                  </div>
               </div>
            </div>
         </div>
         <div class="vitamin_img_wrap">
            <img src={process.env.PUBLIC_URL +'/'+ nunalac_lg.IngrediantsImage} alt="" />
         </div>
      </section>
      <section class="vitamen_content_mainsec">
         <div class="container">
            <div class="row">

               { nunalac_lg.IngrediantsContent.map(ingrediants => (	
               <div class="col-md-6">
                  <div class="nuna_vinamen_contbox  clearfix">
                     <div class="nuna_vinamen_iconbox">
                        <div class="nuna_vinamen_innerbox">
                           {ingrediants.Icon}
                        </div>
                     </div>
                     <div class="nuna_vinamen_contentwrap">
                        <h3>{ingrediants.Headline}</h3>
                        <p>{ingrediants.Description}</p>
                     </div>
                  </div>
               </div>
               ) ) }
               
            </div>
         </div>
      </section>

      <section class="preparation_steps_mainsec">
         <div class="container">
            <div class="row">
               <div class="col-md-6 offset-md-3" id="preparation">
                  <div class="preparation_steps_title_wrap">
                     <h1>{nunalac_lg.PreparationTitle}</h1>
                  </div>
               </div>
            </div>
            <div class="row">

               { nunalac_lg.PreparationBox.map((preparebox,i) => (
               <div class={`col-md-6 col-12 ${i === 6 ? "offset-md-3" : ""}`}>
                  <div class="preparation_step_wrap">
                     <div class="preparation_step_imagewrap">
                        <img src={process.env.PUBLIC_URL +'/'+ preparebox.Image} alt="" />
                     </div>
                     <div class="preparation_step_contentwrap">
                        <h4>{preparebox.Content1}</h4>
                        {/* <h2>{preparebox.Content2}</h2> */}
                     </div>
                  </div>
               </div>
               ) ) }              

            </div>
         </div>
      </section>

      <section class="nunalac_page_tablewrap">
         <div class="container">
            <div class="row">
               <div class="col-lg-12 col-12 col-sm-12">
                  <div class="table-responsive">
                     <div class="nuna_tablewrap">
                        <table class="table table-borderless">
                           <thead>
                              <tr>
                                 <th>{nunalac_lg.Feedings.Column1Title}</th>
                                 <th>{nunalac_lg.Feedings.Column2Title}</th>
                                 <th>{nunalac_lg.Feedings.Column3Title}</th>
                                 <th>{nunalac_lg.Feedings.Column4Title}</th>
                                 <th>{nunalac_lg.Feedings.Column5Title}</th>
                              </tr>
                           </thead>
                           <tbody>
                              { nunalac_lg.Feedings.Content.map(feed => (
                              <tr>
                                 <td>{feed.Age}</td>
                                 <td>{feed.Feedings}</td>
                                 <td>{feed.Water}</td>
                                 <td>{feed.Scoops}</td>
                                 <td>{feed.FeedMl}</td>
                              </tr>
                              ) ) }                              
                              <tr>
                                 <td colspan="5" class="nunalac_table_td_padd_zero">
                                    <div class="nunalac_table_bottomtextwrap">
                                       <h3>{nunalac_lg.Feedings.Footer}</h3>
                                    </div>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="babyage_mainsec">
         <div class="container">
            <div class="row">
               <div class="col-md-6 offset-md-3" id="babyage">
                  <div class="preparation_steps_title_wrap">
                     <h1>{nunalac_lg.BabyAge.Title}</h1>
                  </div>
               </div>
            </div>
            <div class="row">

               <div class="col-lg-6 col-12">
                  <div class="babyage_contouter_wrapnew">
                     <div class="babyage_contouter">
                        <div class="row">
                           { nunalac_lg.BabyAge.Age1.AgeBox.map(age_box => (  
                              <div class="col-lg-4 col-4">
                                 <div class="baby_bottle_wrrap">
                                    <div class="baby_age_contwrap">
                                       {age_box.Category}
                                    </div>
                                    <img src={process.env.PUBLIC_URL +'/'+ age_box.Image} alt="" />
                                 </div>
                              </div>
                           ) ) }
                        </div>
                     </div>
                     <div class="babybottleouterwrap">
                        <div class="row">
                        { nunalac_lg.BabyAge.Age1.Spoons.map(spoon => (  
                           <div class="col-lg-4 col-4">
                              <div class="baby_cup_wrrap">
                                 <div class="nunalac_countwrap">
                                    <span>{spoon.Spoon}</span>
                                 </div>
                                 <img src={process.env.PUBLIC_URL +'/'+ spoon.SpoonImage} alt="" />
                              </div>
                           </div>
                        ) ) }
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-lg-6 col-12">
                  <div class="babyage_contouter_wrapnew">
                     <div class="babyage_contouter">
                        <div class="row">
                        { nunalac_lg.BabyAge.Age2.AgeBox.map(age_box => (  
                           <div class="col-lg-4 col-4">
                              <div class="baby_bottle_wrrap">
                                 <div class="baby_age_contwrap">
                                    {age_box.Category}
                                 </div>
                                 <img src={process.env.PUBLIC_URL +'/'+ age_box.Image} alt="" />
                              </div>
                           </div>
                        ) ) } 
                        </div>
                     </div>
                     <div class="babybottleouterwrap">
                        <div class="row">
                        { nunalac_lg.BabyAge.Age2.Spoons.map(spoon => (  
                           <div class="col-lg-4 col-4">
                              <div class="baby_cup_wrrap">
                                 <div class="nunalac_countwrap">
                                 <span>{spoon.Spoon}</span>
                                 </div>
                                 <img src={process.env.PUBLIC_URL +'/'+ spoon.SpoonImage} alt="" />
                              </div>
                           </div>
                        ) ) } 
                       </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      </div>
  );
}

export default Nunalac;