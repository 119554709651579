import React from "react";
import $ from 'jquery'; 

function Home() {

 const home_lg = require('../../public/content/'+window.language+'/page_baby_care');

 const onSubmitContactForm = (event) => {
    event.preventDefault();
    try {
        $.ajax({
            type: 'POST',
            url: "https://script.google.com/macros/s/AKfycbzT53TYReYHqfp4EIQhKEsTwd-rxhZzc28p7T8za0rb3E2HImkCbblZek4-paAOGdo/exec",
            data: $('#nunaformhome').serialize(),
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Access-Control-Allow-Origin': '*'
              },
            success:function(result)
            { console.log("result", result);
                if(result.result==='success'){
                    $('#name').val('');
                    $('#phone').val('');
                    alert('Thank you for you message. We will get back to you soon');
                }                
            }
          });
        } catch (e) {
            alert(`Registration failed! Sorry Try Again later`);
        }
  }

   return (
    <div className="maincontent">
    <section class="hmepage_mainsec">
      <div class="main_bannerwrap">
          <img src={process.env.PUBLIC_URL + '/assets/img/main_image1.jpg'} alt="" />
      </div>
      <div class="mainbanner_textwrap">
          <div class="container">
              <div class="row">
                  <div class="col-lg-6 col-8">
                      <h1>{home_lg.TopBannerHeading1}</h1>
                      <h2>{home_lg.TopBannerHeading2}</h2>
                      <h2>{home_lg.TopBannerHeading3}</h2>
                  </div>
              </div>
          </div>
      </div>
    </section>

    <section class="amazing_fea_mainsec">
      <div class="container">
      { home_lg.AmazingFeature.map((amazing) => {
       return (
        <div>
        <div class="row">
            <div class="col-md-6 offset-md-3">
                <div class="sectiontitle_wrap">
                    <h1>{amazing.Title}</h1>
                </div>
            </div>
        </div>
        <div class="row">
            {  amazing.AmazingFeatureContent.map((amazingDetail) => {
                return (
                  <div class="col-md-6">
                  <div class="service-inner-box  clearfix">
                      <div class="service-icon-box">
                          <div class="innericon_box">
                              {amazingDetail.Icon}
                          </div>
                      </div>
                      <div class="service-content-box">
                          <h3>{amazingDetail.Title}</h3>
                          <p>{amazingDetail.Description}</p>
                      </div>
                  </div>
              </div>
                );    })    }
        </div>
        </div> 
        );  })   }
      </div>
    </section>

    <section class="hmepage_mainsec">
        <div class="main_bannerwrap">
            <img src={process.env.PUBLIC_URL + '/assets/img/img1.jpg'} alt="Image1" />
        </div>
    </section>

    <section class="differentsize_mainsec">
        <div class="container">
        { home_lg.BabyGrowthSizes.map((babygrowth) => {
       return (
         <div>
            <div class="row">
                <div class="col-md-6 offset-md-3">
                    <div class="sectiontitle_wrap">
                        <h1>{babygrowth.Title}</h1>
                    </div>
                </div>
            </div>
            <div class="row">
              { babygrowth.GrowthSizes.map((babysize) => {
                return (
                <div class="col-md-2 size_widthadj_5col">
                    <div class="diffsize_imagewrap size_img1">
                        <div class="age_wrap">
                            {babysize.Age}
                        </div>
                        <img src={process.env.PUBLIC_URL +'/'+ babysize.Image} alt="size" />
                        <div class="sizewrap">
                            <h3> <span>{babysize.Size}</span> </h3>
                            <h4>{babysize.Title}</h4>
                        </div>
                    </div>
                </div>
                  );  }) }
              </div>
            </div>
          );    })    }
        </div>
    </section>

    <section class="differentpackage_mainsec">
        <div class="container">
        {  home_lg.DifferentPackages.map((pack) => {
       return (
            <div>
            <div class="row">
                <div class="col-md-6 offset-md-3">
                    <div class="sectiontitle_wrap">
                        <h1>{pack.Title}</h1>
                    </div>
                </div>
            </div>
            <div class="row">
            {
              pack.DifferentPackagesBox.map((packtype) => {
                return (
                <div  class="col-md-4">
                    <div class="differntpackage_wrap">
                        <h4>{packtype.BoxTitle}</h4>
                        <ul>
                        {
                          packtype.BoxValues.map((packvalue) => {
                            return ( 
                                    <li>{packvalue.Title} <span>{packvalue.Size}</span></li>
                                   );
                            })
                        }
                        </ul>
                    </div>
                </div>
                 );
                })
              }
            </div>
           </div>
            );   })   }
        </div>
    </section>

    <section class="nuna_babywipes_mainsec">
    {  home_lg.BabyWipes.map((babywipe) => {
       return (
        <div>
        <div  class="container">
            <div class="row">
                <div class="col-md-6 offset-md-3">
                    <div class="sectiontitle_wrap">
                        <h1>{babywipe.Title}</h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="nuna_babywipes_image_wrap">
            <img src={process.env.PUBLIC_URL + '/assets/img/nuna.jpg'} alt="nuna" />
        </div>
        <div  class="container">
            <div class="row">
                <div class="col-md-4 offset-md-4">
                    <div class="nuna_contwrap">
                        <ul>
                        {babywipe.GrowthSizes.map(s => (
                        <li><span></span> {s}</li>
                        ) ) }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        </div>
      );   })   }
    </section>

    <div class="mom_imagewrap">
        <img src={process.env.PUBLIC_URL + '/assets/img/mom.jpg'} alt="MomImage" />
    </div>
    <div class="baby_imagewrap">
        <img src={process.env.PUBLIC_URL + '/assets/img/baby1.jpg'} alt="babyImage" />
    </div>
    <section class="video_image_mainsec">
        <div class="video_image_imagewrap">
            <img src={process.env.PUBLIC_URL + '/assets/img/videoimage.jpg'} alt="videoImage" />
        </div>
        <div class="container">
            <div class="video_title_wrap">
                <h2>{home_lg.UltimatePerformanceTitle}</h2>
                <div class="playwrap">
                    <a href="#!"> 
                      <img src={process.env.PUBLIC_URL + '/assets/img/play.png'} alt="" /> {home_lg.UltimatePerformanceVideo}
                    </a>
                </div>
            </div>
        </div>
    </section>
    <section class="nuna_diaper_mainsec">
        <div class="container">
            <div class="row">
                <div class="col-md-8 offset-md-2">
                    <div class="sectiontitle_wrap">
                        <h1>{home_lg.DaiperForFree.Title}</h1>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-8 offset-md-2">
                    <div class="form_wrap">
                        <form id="nunaformhome" onSubmit={onSubmitContactForm}>
                            <div class="form-group">
                                <input type="hidden" name="page" value="home"/>
                                <label>{home_lg.DaiperForFree.NameField} <span>*</span></label>
                                <input class="form-control" id="name" name="name" type="text" autoComplete="off" required/>
                            </div>
                            <div class="form-group">
                                <label>{home_lg.DaiperForFree.PhoneField} <span>*</span></label>
                                <input class="form-control" id="phone" name="phone" type="number"  min="0" autoComplete="off" required/>
                            </div>
                            <div class="form-group">
                                <label>{home_lg.DaiperForFree.SelectCity} <span>*</span></label>
                                <select class="form-control" name="city">
                                  { home_lg.DaiperForFreeCity.map(city => (
                                    <option value={city}>{city}</option>
                                    ) ) }
                                </select>
                            </div>
                            <div class="form-group">
                                <button type="submit">{home_lg.DaiperForFree.Submit}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>

</div>
  );
}
export default Home;
