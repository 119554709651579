import React from "react";

function Selectmil() {
    
    const selectmil_lg = require('../../public/content/'+window.language+'/page_selectmil');

  return (
    <div>
     <section class="selectmil_mainsec">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-6">
                    <div class="selectmil_logowrap">
                        <img src={process.env.PUBLIC_URL +'/'+ selectmil_lg.TopLeftBanner} alt="banner" />
                    </div>
                </div>
                <div class="col-md-6 col-6">
                    <div class="selectmil_logowrap">
                        {/* <img src={process.env.PUBLIC_URL +'/'+ selectmil_lg.TopRightBanner} alt="banner" /> */}
                    </div>
                </div>
            </div>
        </div>
         <div class="selectmil_mainbanner_sec">
             <img src={process.env.PUBLIC_URL +'/'+ selectmil_lg.SecondBanner} alt="banner" />
         </div>
     </section>

       <section class="gold_adv_mainsec">
           <div class="container">
                <div class="row">
                    <div class="col-md-8 offset-md-2">
                        <div class="sectiontitle_wrap">
                            <h1>{selectmil_lg.FormulaSectionTitle}</h1>
                        </div>
                    </div>
                </div>
               <div class="row">
                   <div class="col-md-12">
                       <div class="goldadv_imgwrap">
                           <img src={process.env.PUBLIC_URL +'/'+ selectmil_lg.FormulaImage} alt="sectionimage" />
                       </div>
                   </div>
               </div>
           </div>
       </section>
       <section class="arrow_cont_mainsec">
           <div class="container">
               <div class="row">
                   <div class="col-md-12">
                       <div class="arrowimg_wrap">
                           <img src={process.env.PUBLIC_URL +'/'+ selectmil_lg.ProgressImage} alt="sectionimage" />
                       </div>
                   </div>
               </div>
           </div>
       </section>
        <section class="direction_mainsec">
           <div class="container">
               <div class="row">
                   <div class="col-md-8 offset-md-2">
                       <div class="sectiontitle_wrap">
                           <h1>{selectmil_lg.HowtoUseTitle}</h1>
                       </div>
                   </div>
               </div>
               <div class="row">
                <div class="col-md-6 col-12">
                        <div class="row">
                            <div class="col-md-6 col-6">
                                <div class="direction_imgwrap">
                                    <img src={process.env.PUBLIC_URL +'/'+ selectmil_lg.Step1Image} alt="" />
                                </div>
                            </div>
                            <div class="col-md-6 col-6">
                                <div class="direction_imgwrap">
                                    <img src={process.env.PUBLIC_URL +'/'+ selectmil_lg.Step2Image} alt="" />
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="direction_contwrap">
                                    <p><span> 1.</span> {selectmil_lg.Step1Title}</p>
                                    <p><span> 2.</span> {selectmil_lg.Step2Title}</p>
                                </div>
                            </div>
                        </div>
                   </div>
                   <div class="col-md-6 col-12">
                        <div class="row">
                                <div class="col-md-6 col-6">
                                    <div class="direction_imgwrap">
                                        <img src={process.env.PUBLIC_URL +'/'+ selectmil_lg.Step3Image} alt="" />
                                    </div>
                                </div>
                                <div class="col-md-6 col-6">
                                    <div class="direction_imgwrap">
                                    <img src={process.env.PUBLIC_URL +'/'+ selectmil_lg.Step4Image} alt="" />
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="direction_contwrap">
                                        <p><span> 3.</span> {selectmil_lg.Step3Title}</p>
                                        <p><span> 4.</span> {selectmil_lg.Step4Title}</p>
                                    </div>
                                </div>
                        </div>
                   </div>
                    <div class="col-md-6 col-12">
                        <div class="row">
                            <div class="col-md-6 col-6">
                                <div class="direction_imgwrap">
                                    <img src={process.env.PUBLIC_URL +'/'+ selectmil_lg.Step5Image} alt="" />
                                </div>
                            </div>
                            <div class="col-md-6 col-6">
                                <div class="direction_imgwrap">
                                    <img src={process.env.PUBLIC_URL +'/'+ selectmil_lg.Step6Image} alt="" />
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="direction_contwrap">
                                    <p><span> 5.</span> {selectmil_lg.Step5Title}</p>
                                    <p><span> 6.</span> {selectmil_lg.Step6Title}</p>
                                </div>
                            </div>
                        </div>
                   </div>
                    <div class="col-md-6 col-12">
                        <div class="row">
                            <div class="offset-md-0 offset-3 col-md-6 col-6">
                                <div class="direction_imgwrap">
                                    <img src={process.env.PUBLIC_URL +'/'+ selectmil_lg.Step7Image} alt="" />
                                </div>
                            </div>                  
                            <div class="col-md-12">
                                <div class="direction_contwrap">
                                    <p><span> 7.</span> {selectmil_lg.Step7Title}</p>
                                </div>
                            </div>
                        </div>
                   </div>
               </div>
           </div>
       </section>
       <section class="suggested_feed_mainsec">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-8 offset-md-2">
                        <div class="sectiontitle_wrap">
                            <h1>{selectmil_lg.SuggestedTitle}</h1>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="suggested_contwrap">
                            <h4>{selectmil_lg.SuggestedContent1}</h4>
                            <h4>{selectmil_lg.SuggestedContent2}</h4>
                            <h4>{selectmil_lg.SuggestedContent3}</h4>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <table class="table table-borderless suggested_tablecontwrap">
                            <thead>
                                <tr>
                                    <th>{selectmil_lg.Tableth1}</th>
                                    <th>{selectmil_lg.Tableth2}</th>
                                    <th>{selectmil_lg.Tableth3}</th>
                                    <th>{selectmil_lg.Tableth4}</th>
                                    <th>{selectmil_lg.Tableth5}</th>
                                </tr>
                            </thead>
                            <tbody>
                                { selectmil_lg.SuggestedContent.map(content => (
                                    <tr>
                                        <td dangerouslySetInnerHTML={{__html:content.Column1}}></td>
                                        <td>{content.Column2}</td>
                                        <td>{content.Column3}</td>
                                        <td>{content.Column4}</td>
                                        <td>{content.Column5}</td>
                                    </tr>
                                ) ) }
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
       </section>
    </div>
  );
}

export default Selectmil;