import React from "react";
import './App.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Navigation, Brands , Footer, BabyCare, BeautyCare,AdultCare, Medicine, ExpressClinic, About, Career, Locations, Home, InfantFormula, BabyFoods, FeminineCare,Selectmil } from "./components";
// import { Component } from "react";

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//           <img src={process.env.PUBLIC_URL + '/assets/img/logo.png'} alt="Site Logo" /> 
//           <h1>Website Under Construction</h1>
//       </header>
//     </div>
//   );
// }

function App() {

  window.language = 'en';
  window.mail_folder = 'http://localhost/sharma/react/dauss_web_site/';
  // window.mail_folder = 'http://localhost:3000/';

  return (
    <div className="App">
      <Router>
        <Navigation/>
        <Switch>
          <Route path="/" exact component={() => <Home />} />
          <Route path="/baby-care" exact component={() => <BabyCare />} />
          <Route path="/adult-care" exact component={() => <AdultCare />} />
          <Route path="/feminine-care" exact component={() => <FeminineCare />} />
          <Route path="/beauty-care" exact component={() => <BeautyCare />} />
          <Route path="/infant-formula" exact component={() => <InfantFormula />} />
          <Route path="/baby-foods" exact component={() => <BabyFoods />} />
          <Route path="/selectmil" exact component={() => <Selectmil />} />
          <Route path="/medicine" exact component={() => <Medicine />} />
          <Route path="/express-clinic" exact component={() => <ExpressClinic />} />
          <Route path="/about" exact component={() => <About />} />
          <Route path="/career" exact component={() => <Career />} />
          <Route path="/locations" exact component={() => <Locations />} />         
        </Switch>
        <Brands/>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
