import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import $ from 'jquery'; 
import { useHistory } from 'react-router-dom';  
function Landing() {
   const landing_lg = require('../../public/content/'+window.language+'/page_landing');
   const history = useHistory();

   const onSubmitContactForm = (event) => {
      event.preventDefault();
      try {
        $.ajax({
         type: 'POST',
         url: "https://script.google.com/macros/s/AKfycbzT53TYReYHqfp4EIQhKEsTwd-rxhZzc28p7T8za0rb3E2HImkCbblZek4-paAOGdo/exec",
         data: $('#nunaformhome').serialize(),
         headers: { 
            'Content-Type': 'application/x-www-form-urlencoded',
            'Access-Control-Allow-Origin': '*'
          },
         success:function(result)
         { console.log("result", result);
             if(result.result==='success'){
                    $('#name').val('');
                    $('#phone').val('');
                    $('#email').val('');
                    $('#comment').val('');
                    alert('Your registration was successfully submitted!');
                }else{
                   alert('Datas Not submitted!');
                }
            }
          });
        } catch (e) {
            alert(`Datas Not submitted! ${e.message}`);
        }
    }

  return (
      <div>
      <div class="owl-slider">
         <OwlCarousel margin={0} items={1} autoplay ={true} smartSpeed={800} autoplayTimeout={7000} loop nav id="carousel" class="owl-carousel">
	    { landing_lg.Banner.map(slider_img => (
               <div class="item">
                  <img src={process.env.PUBLIC_URL +'/'+ slider_img} alt="banner" />
               </div>
	    ) ) }
         </OwlCarousel>
      </div>

      <section class="landingpage_contentand_imagewrap">
         <div class="container">
            <div class="row">
               <div class="col-md-8 offset-md-2">
                  <div class="landing_page_contwrap">
                     <h4>{landing_lg.HeaderContent}</h4>
                  </div>
               </div>
            </div>
         </div>
         <div class="landingpage_imagewrap">
            <img src={process.env.PUBLIC_URL +'/'+ landing_lg.BabyImage} alt="" />
         </div>
      </section>
      <section class="landingpage_iconwrap_mainsec">
         <div class="container-fluid">
            <div class="row">
	       { landing_lg.Box.map(box => (
               <div class="col-md-4 col-6">
                  <a onClick={() => history.push(box.Link)}>
                  <div class="landingpage_iconwrap">
                     <h4>{box.Heading}</h4>
                     <div class="iconimg_wrap">
                        <img src={box.Icon} alt="" />
                     </div>
                  </div>
                  </a>
               </div>
	       ) ) }
            </div>
         </div>
      </section>
      <section class="locationpage_contacts_mainsec">
         <div class="container">
            <div class="row">
               <div class="col-md-8 offset-md-2">
                  <div class="sectiontitle_wrap contactus_texttransform">
                     <h1>{landing_lg.ContactUs.Title}</h1>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-md-8 offset-md-2">
                  <div class="form_wrap">
                     <form id="contactus_form" class="landingpage_formwrap" onSubmit={onSubmitContactForm}>
                        <div class="form-group">
                           <input type="hidden" name="page" value="landing"/>
                           <label>{landing_lg.ContactUs.NameLabel} <span>*</span></label>
                           <input class="form-control" id="name" name="name" type="text" required autoComplete="off"/>
                        </div>
                        <div class="form-group">
                           <label>{landing_lg.ContactUs.PhoneLabel} <span>*</span></label>
                           <input class="form-control" id="phone" name="phone" type="number" required autoComplete="off"/>
                        </div>
                        <div class="form-group">
                           <label>{landing_lg.ContactUs.EmailLabel} <span>*</span></label>
                           <input class="form-control" id="email" name="email" type="email" required autoComplete="off"/>
                        </div>
                        <div class="form-group">
                           <label>{landing_lg.ContactUs.CommentLabel} <span>*</span></label>
                           <textarea class="form-control" required name="comment" id="comment"></textarea>
                        </div>
                        <div class="form-group contctus_buttonwrap landingpage_submit">
                           <button type="submit">{landing_lg.ContactUs.Submit}</button>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </section>
      </div>
  );
}

export default Landing;