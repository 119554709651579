import React from "react";
import $, { type } from 'jquery'; 
import { unstable_renderSubtreeIntoContainer } from "react-dom";

function ExpressClinic() {
  
  const express_lg = require('../../public/content/'+window.language+'/page_express_clinic');
  const adult_medicine = require('../../public/content/'+window.language+'/adult_medicine');
  const children_medicine = require('../../public/content/'+window.language+'/children_medicine');
  const vitamins = require('../../public/content/'+window.language+'/vitamins');
    
 const SearchMedicineClinic = (event) => {
    event.preventDefault();
    var selected_type = $('#user_selected_type').val();
    var type_data='';
    if(selected_type === '' || selected_type === 'adult'){type_data=adult_medicine;}else{type_data=children_medicine;}
    var medicine_clinic = [];
    $("input[name='medicine_clinic[]']:checked").each(function(){medicine_clinic.push($(this).val());});   

    var input=[];
    var output=[];
    var html='';
    if(medicine_clinic.length>0){
        $.each(medicine_clinic , function (index, issue){ 
            if(type_data[issue]!==''){
                output.push(type_data[issue]);
            }
        });
    }
    
    $.each(output , function (index1, out){ 
    $.each(out , function (index2, value){ 
            var data={title:value['title'],Image:value['Image']};
            input.push(data);
        });
    });
    input=unique(input);
    html+='<div class="modal" id="myModal"><div class="modal-dialog modal-lg modal-xl"><div class="modal-content"><div class="modal-header"><h4 class="modal-title express_clinic_searchwrap">';
    if(medicine_clinic.length>0){
        $.each(medicine_clinic , function (index3, issue1){
          html+='<span>'+issue1+'<i class="fas fa-times-circle"></i></span>';
        });
    }
    html+='</h4></div><div class="modal-body"><div class="row">';
    if(input.length>0){
        $.each(input , function (index4, value1){
            html+='<div class="col-6 col-md-4"><div class="searchsesult_wrap"><img src="/'+value1['Image']+'" alt=""/></div></div>';
        });
    } else {
        html+='<p><center><h4>SORRY NO RESULTS FOUND.</h4></center></p>';
    }
    html+='</div></div><div class="modal-footer"><button type="button" class="btn btn-danger" onClick=CloseModel()>Close</button></div></div></div></div><script>function CloseModel(){$("#myModal").modal("hide");}</script>';

    $('#medicine_model_div').html(html);
    window.$('#myModal').modal('show');
  }

   const SearchVitaminClinic = (event) => {
    event.preventDefault();
    var selected_type = $('#user_selected_vitamin_type').val();
    var type_data='';
    if(selected_type === '' || selected_type === 'adult'){type_data=vitamins;}else{type_data=vitamins;}
    var vitamin_clinic = [];
    $("input[name='vitamin_clinic[]']:checked").each(function(){vitamin_clinic.push($(this).val());}); 
    var input=[];
    var output=[];
    var html='';
    if(vitamin_clinic.length>0){
        $.each(vitamin_clinic , function (index, issue){
            if(type_data[issue]!==''){
                output.push(type_data[issue]);
            }
        });
    }
    $.each(output , function (index1, out){
    $.each(out , function (index2, value){
            var data={title:value['title'],Image:value['Image']};
            input.push(data);
        });
    });
    input=unique(input);
    
    html+='<div class="modal" id="vitaminModal"><div class="modal-dialog modal-lg modal-xl"><div class="modal-content"><div class="modal-header"><h4 class="modal-title express_clinic_searchwrap">';
    if(vitamin_clinic.length>0){
        $.each(vitamin_clinic , function (index3, issue1){
          html+='<span>'+issue1+'<i class="fas fa-times-circle"></i></span>';
        });
    }
    html+='</h4></div><div class="modal-body"><div class="row">';
    if(input.length>0){
        $.each(input , function (index4, value1){
            html+='<div class="col-6 col-md-4"><div class="searchsesult_wrap"><img src="/'+value1['Image']+'" alt=""/></div></div>';
        });
    } else {
        html+='<p><center><h4>SORRY NO RESULTS FOUND.</h4></center></p>';
    }
    html+='</div></div><div class="modal-footer"><button type="button" class="btn btn-danger" onClick=VitaminCloseModel()>Close</button></div></div></div></div><script>function VitaminCloseModel(){$("#vitaminModal").modal("hide");}</script>';

    $('#medicine_model_div').html(html);
    window.$('#vitaminModal').modal('show');
  }
  
  function unique(array){
    return array.filter(function(el, index, arr) {
        return index === arr.indexOf(el);
    });
  }
  function ClearCheckbox(){
    $('input[name="medicine_clinic[]"]').each(function() {
        this.checked = false;
    });
  }
  function ClearCheckboxVitamin(){
    $('input[name="vitamin_clinic[]"]').each(function() {
        this.checked = false;
    });
  }
  function SelectType(name) {
    $('#user_selected_type').val(name);
    if(name==='adult'){
        $("#children_li").removeClass("active");$("#adult_li").addClass("active");
    }
    else{
        $("#children_li").addClass("active");$("#adult_li").removeClass("active");
    }
  }
  function SelectTypeVitamin(name) {
    $('#user_selected_vitamin_type').val(name);
    if(name==='adult'){
        $("#children_vitamin_li").removeClass("active");$("#adult_vitamin_li").addClass("active");
    }
    else{
        $("#children_vitamin_li").addClass("active");$("#adult_vitamin_li").removeClass("active");
    }
  }
  
  return (
      <section class="expressclinicpage_mainsec">
      <div class="healthcare_navpills">
        <ul class="nav nav-tabs">
            <li class="nav-item">
                <a class="nav-link medicine_btn active" data-toggle="tab" href="#Medicine">{express_lg.TopAnchor1}</a>
            </li>
            <li class="nav-item">
                <a class="nav-link vitamins_btn" data-toggle="tab" href="#Vitamins">{express_lg.TopAnchor2}</a>
            </li>
        </ul>
      </div>
      <div class="healthcare_tabcont">
        <div class="tab-content">
            <div class="tab-pane active" id="Medicine">
                <div class="expressclinic_main_contwrap">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="express_short_texterap">
                                    <h1>{express_lg.TopAnchor1TopHeading1}</h1>
                                    <h3>{express_lg.TopAnchor1TopHeading2}</h3>
                                    <h3>{express_lg.TopAnchor1TopHeading3}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="expressclinic_imagewrap"> 
                        <img src={process.env.PUBLIC_URL + '/assets/img/express_clinic/mainimg1.jpg'} alt=""/>
                    </div>
                </div>
                <section class="expressclinic_mainsec">

                { express_lg.MedicineExpressClinic.map((express_c1) => {
                return (

                    <div class="container">
                        <div class="row">
                            <div class="col-md-6 offset-md-3">
                                <div class="expressclinic_titlewrap">
                                    <h1>{express_c1.Title}</h1>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="clinic_topbuttonwrap">
                                    <input type="hidden" id="user_selected_type" />
                                    <ul>
                                        <li id="adult_li" class="active">
                                            <a href="express-clinic#expressclinic_mainsec" onClick={() => SelectType('adult')}>
                                            {express_c1.AnchorTitle1}
                                                <div class="clinicbtn_subtext">{express_c1.AnchorDescription1}</div>
                                            </a>
                                        </li>
                                        <li id="children_li">
                                            <a href="express-clinic#expressclinic_mainsec" onClick={() => SelectType('children')}>
                                            {express_c1.AnchorTitle2}
                                                <div class="clinicbtn_subtext">{express_c1.AnchorDescription2}</div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="row" id="medicine_clinic_div">
                          
                          { express_c1.ClinicList.map((express_c1_l) => {
                          return (

                              <div class="col-lg-3 col-sm-3 col-6">
                                  <div class="clinic_icontexterap">
                                      <label class="express_clinic_list_container">
                                          <div class="clinic_img_iconwrap"><img src={process.env.PUBLIC_URL +'/'+ express_c1_l.Image} alt=""/></div>
                                          <div class="clinic_innter_wrap">
                                              <input type="checkbox" id={express_c1_l.ClinicListContent} name="medicine_clinic[]" value={express_c1_l.ClinicListContent} />
                                              <label for={express_c1_l.ClinicListContent}><h3> {express_c1_l.ClinicListContent} </h3></label>
                                          </div>
                                      </label>
                                  </div>
                              </div>

                          );    })    }

                        </div>
                         <div class="row">
                            <div  class="col-md-4 offset-md-2">
                                <div class="showrecommenf_btnwrap active">
                                    <a href="express-clinic#" onClick={SearchMedicineClinic}>{express_c1.BottomAnchorContent1}</a>
                                </div>
                            </div>
                            <div  class="col-md-4">
                                <div class="showrecommenf_btnwrap">
                                    <a href="express-clinic#expressclinic_mainsec" onClick={ClearCheckbox}>{express_c1.BottomAnchorContent2}</a>
                                </div>
                            </div>
                        </div>
                    </div>

                );    })    }

                </section>
            </div>

            <div class="tab-pane  fade" id="Vitamins">
                <div class="expressclinic_main_contwrap">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="express_short_texterap">
                                    <h1>{express_lg.TopAnchor2TopHeading1}</h1>
                                    <h3>{express_lg.TopAnchor2TopHeading2}</h3>
                                    <h3>{express_lg.TopAnchor2TopHeading3}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="expressclinic_imagewrap"> 
                        <img src={process.env.PUBLIC_URL + '/assets/img/express_clinic/mainimg2.jpg'} alt=""/>
                    </div>
                        <section class="expressclinic_mainsec" id="expressclinic_mainsec">
                    
                    { express_lg.VitaminsExpressClinic.map((express_c2) => {
                    return (

                    <div class="container">
                        <div class="row">
                            <div class="col-md-6 offset-md-3">
                                <div class="expressclinic_titlewrap">
                                    <h1>{express_c2.Title}</h1>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="clinic_topbuttonwrap">
                                <input type="hidden" id="user_selected_vitamin_type" />
                                    <ul>
                                        <li class="active" id="adult_vitamin_li">
                                            <a href="express-clinic#expressclinic_mainsec" onClick={() => SelectTypeVitamin('adult')}>
                                            {express_c2.AnchorTitle1}  
                                                <div class="clinicbtn_subtext">{express_c2.AnchorDescription1}</div>
                                            </a>
                                        </li>
                                        <li id="children_vitamin_li">
                                            <a href="express-clinic#expressclinic_mainsec" onClick={() => SelectTypeVitamin('children')}>
                                            {express_c2.AnchorTitle2} 
                                                <div class="clinicbtn_subtext">{express_c2.AnchorDescription2}</div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            
                        { express_c2.ClinicList.map((express_c2_l) => {
                          return (

                            <div class="col-lg-3 col-sm-3 col-6">
                                <div class="clinic_icontexterap">
                                    <label class="express_clinic_list_container">
                                        <div class="clinic_img_iconwrap"><img src={process.env.PUBLIC_URL +'/'+ express_c2_l.Image} alt=""/></div>
                                        <div class="clinic_innter_wrap">
                                            <input type="checkbox" id={express_c2_l.ClinicListContent} name="vitamin_clinic[]" value={express_c2_l.ClinicListContent} />
                                            <label for={express_c2_l.ClinicListContent}><h3> {express_c2_l.ClinicListContent} </h3></label>
                                        </div>
                                    </label>
                                </div>
                            </div>
                          
                          );    })    }

                        </div>
                        <div class="row">
                            <div class="col-md-4 offset-md-2">
                                <div class="showrecommenf_btnwrap active">
                                    <a href="express-clinic#expressclinic_mainsec" onClick={SearchVitaminClinic}>{express_c2.BottomAnchorContent1}</a>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="showrecommenf_btnwrap">
                                    <a href="express-clinic#expressclinic_mainsec" onClick={ClearCheckboxVitamin}>{express_c2.BottomAnchorContent2}</a>
                                </div>
                            </div>
                        </div>
                    </div>

                  );    })    }
                    
                 </section>
                </div>
            </div>
        </div>
      </div>
      
      <div id="medicine_model_div"></div>

      </section>
  );
}

export default ExpressClinic;