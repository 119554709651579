import React from "react";
import { useHistory } from 'react-router-dom';




function Brands() {
  
  const brands = require('../../public/content/'+window.language+'/section_brands');
  const history = useHistory();

  return (
    <section class="ourbrand_mainsec">
            <div class="container">
                <div  class="container">
                    <div class="row">
                        <div class="col-md-6 offset-md-3">
                            <div class="sectiontitle_wrap">
                                <h1>{brands.OurBrands.Title}</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                { brands.OurBrands.BrandImages.map((brandimage,i) => (
                    <div  class={`col-md-2 brand_contadj col-6 ${i === 10 ? "offset-md-5 offset-3" : ""}`}>
                        <div class="brand_imagewrap">
                            <a href="#" onClick={() => history.push(brandimage.Link)}><img src={process.env.PUBLIC_URL +'/'+ brandimage.Image} alt="" /></a>
                        </div>
                    </div>
                    ) ) }
                </div>
            </div>
        </section>
  );
}

export default Brands;