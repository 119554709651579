import React from "react";
import $ from 'jquery'; 

function BeautyCare() {
  
  const beautycare_lg = require('../../public/content/'+window.language+'/page_beauty_care');
  function ChangeSize(){
    var product=$('#product').val();
    var sizes=$('#sizes').val();
    if(product!=='' && sizes!==''){
        if($("#"+product+'-'+sizes).length === 0) {}else{
            $('html, body').animate({
                scrollTop: $('#'+product+'-'+sizes).offset().top
            }, 200);
            return false;
        }
    }
   }

  return (
    <div>        
     <section class="ebyan_mainsec">
         <div class="ebyan_mainbanner_sec">
            <img src={process.env.PUBLIC_URL + '/'+beautycare_lg.TopBannerImg}  alt=""/>
         </div>
     </section>
        <section class="ebyanform_mainsec">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 offset-md-2">
                        <div class="location_dropdown">
                            <form class="location_form">
                                <div class="location_wrapform ebyan_formwrap">
                                    <select class="form-control" name="product" id="product" onChange={ChangeSize}>
                                        <option value="">Choose Product</option>
                                        <option value="Shampoo">Shampoo</option>
                                        <option value="Conditioner">Conditioner</option>
                                        <option value="Lotion">Lotion</option>
                                    </select>
                                </div>
                                <div class="location_wrapform ebyan_formwrap">
                                    <select class="form-control" name="sizes" id="sizes" onChange={ChangeSize}>
                                        <option value="">Choose  Size</option>
                                        <option value="1000mL">1000mL</option>
                                        <option value="500mL">500mL</option>
                                        <option value="59mL">59mL</option>
                                    </select>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>

         <section class="ebyanpage_products_mainsec">
             <div class="container">
                    <div class="row">
                        <div class="col-md-6 offset-md-3">
                            <div class="ebyan_sectiontitle_wrap" id="Shampoo-1000mL">
                                <h1>{beautycare_lg.ShampooSection1.Title}</h1>
                                <h3>{beautycare_lg.ShampooSection1.Size}</h3>
                            </div>
                        </div>
                    </div>
                </div>
             <div class="container">
                 <div class="row">
                 { beautycare_lg.ShampooSection1.Images.map(image => (	
                     <div class="col-lg-4 col-4 col-sm-4">
                         <div class="ebyan_product_listwrap">
                             <img src={process.env.PUBLIC_URL + '/'+image}  alt=""/>
                         </div>
                     </div>
                 ) ) }
                 </div>
             </div>
         </section> 

          <section class="ebyanpage_products_mainsec gray_colorbg">
             <div class="container">
                    <div class="row">
                        <div class="col-md-6 offset-md-3">
                           <div class="ebyan_sectiontitle_wrap" id="Shampoo-500mL">
                                <h1>{beautycare_lg.ShampooSection2.Title}</h1>
                                <h3>{beautycare_lg.ShampooSection2.Size}</h3>
                            </div>
                        </div>
                    </div>
                </div>
             <div class="container">
                 <div class="row">
                 { beautycare_lg.ShampooSection2.Images.map(image => (	
                     <div class="col-lg-4 col-4 col-sm-4">
                         <div class="ebyan_product_listwrap">
                             <img src={process.env.PUBLIC_URL + '/'+image}  alt=""/>
                         </div>
                     </div>
                 ) ) }
                 </div>
             </div>
         </section>

         <section class="ebyanpage_products_mainsec">
             <div class="container">
                    <div class="row">
                        <div class="col-md-6 offset-md-3">
                           <div class="ebyan_sectiontitle_wrap" id="Conditioner-1000mL">
                                <h1>{beautycare_lg.Conditioner1.Title}</h1>
                                <h3>{beautycare_lg.Conditioner1.Size}</h3>
                            </div>
                        </div>
                    </div>
                </div>
             <div class="container">
                 <div class="row">
                 { beautycare_lg.Conditioner1.Images.map(image => (	
                     <div class="col-lg-4 col-4 col-sm-4">
                         <div class="ebyan_product_listwrap">
                            <img src={process.env.PUBLIC_URL + '/'+image}  alt=""/>
                         </div>
                     </div>
                  ) ) }
                 </div>
             </div>
         </section>

           <section class="ebyanpage_products_mainsec gray_colorbg">
             <div class="container">
                    <div class="row">
                        <div class="col-md-6 offset-md-3">
                           <div class="ebyan_sectiontitle_wrap" id="Lotion-59mL">
                                <h1>{beautycare_lg.Lotion.Title}</h1>
                                <h3>{beautycare_lg.Lotion.Size}</h3>
                            </div>
                        </div>
                    </div>
                </div>
             <div class="container">
                 <div class="row">
                 { beautycare_lg.Lotion.Images.map(image => (	
                     <div class="col-lg-4 col-4 col-sm-4">
                         <div class="ebyan_product_listwrap">
                         <img src={process.env.PUBLIC_URL + '/'+image}  alt=""/>
                         </div>
                     </div>
                 ) ) } 
                 </div>
             </div>
         </section>

          <section class="ebyanpage_products_mainsec">
             <div class="container">
                    <div class="row">
                        <div class="col-md-6 offset-md-3">
                           <div class="ebyan_sectiontitle_wrap" id="Shampoo-59mL">
                                <h1>{beautycare_lg.ShampooSection3.Title}</h1>
                                <h3>{beautycare_lg.ShampooSection3.Size}</h3>
                            </div>
                        </div>
                    </div>
                </div>
             <div class="container">
                 <div class="row">
                 { beautycare_lg.ShampooSection3.Images.map(image => (	
                     <div class="col-lg-4 col-4 col-sm-4">
                         <div class="ebyan_product_listwrap">
                            <img src={process.env.PUBLIC_URL + '/'+image}  alt=""/>
                         </div>
                     </div>
                 ) ) } 
                 </div>
             </div>
         </section>

         <section class="ebyanpage_products_mainsec gray_colorbg">
             <div class="container">
                    <div class="row">
                        <div class="col-md-6 offset-md-3">
                           <div class="ebyan_sectiontitle_wrap" id="Conditioner-59mL">
                                <h1>{beautycare_lg.Conditioner2.Title}</h1>
                                <h3>{beautycare_lg.Conditioner2.Size}</h3>
                           </div>
                        </div>
                    </div>
                </div>
             <div class="container">
                 <div class="row">
                    {beautycare_lg.Conditioner2.Images.map(image=>(
                        <div className="col-lg-4 col-4 col-sm-4">
                            <div className="ebyan_product_listwrap">
                                <img src={process.env.PUBLIC_URL+'/'+image} alt=""/>
                            </div>
                        </div>
                    ))}
                 </div>
             </div>
         </section>

    </div>
  );
}

export default BeautyCare;