import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
function Career() {
   
  const careers_lg = require('../../public/content/'+window.language+'/page_careers');

  return (
      <div>
      <section class="careers_mainsec">
         <div class="container">
            <div class="col-md-12">
               <div class="career_contwrap">
                  <h3 dangerouslySetInnerHTML={{__html:careers_lg.TopHeading}}></h3>
               </div>
            </div>
         </div>
      </section>
      <div class="owl-slider">
      <OwlCarousel margin={0} items={1} autoplay ={true} smartSpeed={800} autoplayTimeout={7000} loop nav id="carousel" class="owl-carousel">
            { careers_lg.SliderImages.map(slider_img => (	
               <div class="item">
                  <img src={process.env.PUBLIC_URL +'/'+ slider_img} alt="banner" />
               </div>
            ) ) }
      </OwlCarousel>
      </div>
      <section class="whowehire_mainsec">
         <div class="container">
            <div class="row">
               <div class="col-md-6 offset-md-3">
                  <div class="career_title_wrap career_title_uppercase">
                     <h1>{careers_lg.CareerTitle}</h1>
                  </div>
               </div>
            </div>
            <div class="row">
	       { careers_lg.CareerBox.map(career_box => (
               <div class="col-lg-6 col-sm-6 col-12">
                  <div class="hire_contwrap">
                     <div class="hire_imagewrap">
                        <img src={process.env.PUBLIC_URL +'/'+ career_box.Image} alt="" />
                     </div>
                     <div class="hire_innercontwrap">
                        <h3>{career_box.Title}</h3>
                        <h4>{career_box.Content}</h4>
                     </div>
                  </div>
               </div>
	       ) ) }
            </div>
         </div>
      </section>

      <section class="hiringprocess_mainsec">
         <div class="container">
            <div class="row">
               <div class="col-md-6 offset-md-3">
                  <div class="career_title_wrap career_title_uppercase">
                     <h1>{careers_lg.HiringProcess}</h1>
                  </div>
               </div>
            </div>
         </div>
         
	 { careers_lg.HiringProcessBox.map(hire_process_box => (
         <div class={`tips_mainsec ${hire_process_box.BgWhite === "0" ? "bg_grap" : ""}`}>
            <div class="container">
               <div class="row">
                  <div class="col-md-8 offset-md-2">
                     <div class="row">
                        <div class="col-lg-9 col-sm-12 col-12">
                           <div class="tips_content_wrap hire_process_wrap">
                              <h3>{hire_process_box.Title}</h3>
                              <h4>{hire_process_box.Content}</h4>
                           </div>
                        </div>
                        <div class="col-lg-3 col-sm-12 col-12">
                           <div class="tip_iconwrap">
                              <img src={process.env.PUBLIC_URL +'/'+ hire_process_box.Image} alt="" />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>   
	 ) ) }         
      </section>

      <section class="tip_mainsec">
         <div class="container">
            <div class="row">
               <div class="col-md-6 offset-md-3">
                  <div class="career_title_wrap career_title_uppercase">
                     <h1>{careers_lg.HiringTips}</h1>
                  </div>
               </div>
            </div>
         </div>
         
	
	 { careers_lg.HiringTipsBox.map(hire_tips_box => (
         <div class={`tips_mainsec ${hire_tips_box.BgWhite === "0" ? "bg_grap" : ""}`} >
            <div class="container">
               <div class="row">
                  <div class="col-md-8 offset-md-2">
                     <div class="row">
                        <div class="col-lg-9 col-sm-12 col-12">
                           <div class="tips_content_wrap">
                              <h2>{hire_tips_box.Title}</h2>
                              <h4 dangerouslySetInnerHTML={{__html:hire_tips_box.Content}}></h4>
                           </div>
                        </div>
                        <div class="col-lg-3 col-sm-12 col-12">
                           <div class="tip_iconwrap">
                              <img src={process.env.PUBLIC_URL +'/'+ hire_tips_box.Image} alt="" />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         ) ) }       
         
         
         <div class="tips_mainsec blank_wrap bg_grap">
         </div>
      </section>
      </div>
  );
}

export default Career;